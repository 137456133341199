// userSlice.js
import { createSlice } from '@reduxjs/toolkit';

const loginUserSlice = createSlice({
  name: 'userLogin',
  initialState: {
    data: null,
    loading: false,
    error: null,
    selected_analytics_Ids:[]

  },
  reducers: {
    fetchLoginUserStart: (state) => {
      state.loading = true;
    },
    fetchLoginUserSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    },
    fetchLoginUserFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    get_Analytics_Ids_ReduxState: (state,action) => {
      state.selected_analytics_Ids = action.payload;
      state.loading = false;

    },

  },
});

export const {
  fetchLoginUserStart,
  fetchLoginUserSuccess,
  fetchLoginUserFailure,
  get_Analytics_Ids_ReduxState
} = loginUserSlice.actions;

export default loginUserSlice.reducer;
